<template>
    <div>
        <div v-if="!appletHasError">
            <div class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row bg-vai-gray-200 px-2 fs-2 py-1">
                <span class="mr-3">Search For: {{displayedSearchTerm}}</span>
                <small>{{matchesCount}} Results</small>
            </div>
            <b-row class="px-2">
                <b-col>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                    ></b-pagination>
                </b-col>
            </b-row>
            <b-table 
                class="px-2"
                ref="mainSearchTable"
                stacked="sm"
                :busy.sync="isBusy"
                striped 
                hover 
                small 
                dark 
                :items="searchProvider" 
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :current-page="currentPage"
                :per-page="perPage"
            >
                <!-- <template v-slot:cell(ID)="data">
                    {{data.item.id}}
                </template> -->
                <template v-slot:cell(controls)="data">
                    <b-button-group size="sm">
                        <b-button class='material-icons' variant="info" :href="showFormForItem(data.item)">pageview</b-button>
                    </b-button-group>
                </template>
            </b-table>
        </div>
        
        <div v-else>
            <b-container>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center">
                            <b-img src="/warning.svg" fluid alt="Fluid image"></b-img>
                        </div>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center" >
                            <h3>{{appletErrorValue}}</h3>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
/**
 * Known Issues:
 * 
 * 
 * TODO:
 *  drop down checklist for visible_fields
 */

const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const blacklist = /(\'|;|\'|\"|\#)/gmi;

export default {
    name: 'searchapplet',
    props:{
        searchTerm: String
    },
    data(){
        return{
            // Control Surfaces
            appletLoadCompleted: false,
            appletHasError: false,
            appletErrorValue: '',
            expectSearchAgainst: '',
            matchesCount: 0,
            fields: [
                // { key: 'ID', label: 'ID', sortable: true },
                { key: 'match_percent', label: 'Match %', sortable: true},
                { key: 'billing_info', label: 'Billing Info', sortable: true },
                { key: 'type', label: 'Type', sortable: true },
                { key: 'match', label: 'Matches On', sortable: true },
                { key: 'text', label: 'Text', sortable: true },
                { key: 'record_status', label: 'Record Status', sortable: true },
                { key: 'controls', label: 'Controls', sortable: false },
            ],
            values: [],
            sortBy: 'match_percent',
            sortDesc: true,
            currentPage: 1,
            perPage: 200,
            totalRows: 0,
            isBusy: false
        }
    },
    computed:{
       displayedSearchTerm: function(){
           if(this.searchTerm != null && this.searchTerm != undefined){
                return this.searchTerm.replace(blacklist, "");
           }
       } 
    },
    watch:{
        searchTerm(newVal, oldVal){
            if(newVal !== oldVal){
                this.appletLoadCompleted = false;
                this.appletHasError = false;
                this.appletErrorValue = '';
                this.refreshSelf();
            }
        }
    },
    methods: {
        refreshSelf(){
            this.sortBy = 'match_percent';
            this.sortDesc = true;
            this.currentPage = 1;
            this.perPage = 200;
            this.totalRows = 0;
            this.$refs.mainSearchTable.refresh();
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        showFormForItem(item){
            var thisFormType = item.plural_type;
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + item.id;
            }
        },
        searchProvider(ctx){
            document.title = `Searching...`;
            // console.log("Called Search Provider")
            // console.log(ctx)
            var paramArray = [];
            paramArray.push(`page=${ctx.currentPage}`)
            paramArray.push(`perPage=${ctx.perPage}`)
            paramArray.push(`sortBy=${ctx.sortBy}`)
            paramArray.push(`sortDesc=${ctx.sortDesc}`)
            var encodedSearchTerm = encodeURIComponent(this.searchTerm);
            this.searchActive = true;
            let promise = instance.get(process.env.VUE_APP_API_BASE_URL + `/search/term/${encodedSearchTerm.toUpperCase()}?${paramArray.join("&")}`)
            return promise.then((response)=>{
                this.totalRows = response.data.meta.totalRows;
                this.matchesCount = response.data.meta.totalRows;
                document.title = `${this.searchTerm} (${this.matchesCount} Results)`;
                var tempTableVals = [];
                //console.log("You Called Sir");
                //console.log(appletLoadObj)
                response.data.result.records.forEach((rec)=>{
                    if(rec.record_status != ''){
                        //rec._rowVariant = 'secondary';
                        rec._cellVariants = { record_status: 'danger'}
                    }
                    var tmp = {
                        ...rec,
                        match_percent: Math.round(rec.match_strength * 100) + "%",
                        type: this.capitalizeFirstLetter(rec.singular_type),
                        match: rec.matched_on,
                        text: rec.txt
                    };
                    tempTableVals.push(tmp);
                })
                this.appletLoadCompleted = true;
                return(tempTableVals);
            }).catch((err)=>{
                // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2021-01-19
                console.log(`Failed To Fetch Search Data`)
                console.log(err)
                this.makeToast("Failed To Fetch Search Results", "An error has occured durring the search operation.", 'danger', 30)
                return(err);
            })
        },

        // search(){
        //     // encodeURLComponent the search term
        //     var encodedSearchTerm = encodeURIComponent(this.searchTerm);
        //     this.searchActive = true;
        //     //Get User Token and Fetch The Values Required For This Page
        //     instance.get(process.env.VUE_APP_API_BASE_URL + '/search/term/' + encodedSearchTerm.toUpperCase())
        //         .then( (response) => {
        //             this.showSearchResults({ res: response.data.result, term: this.searchTerm.toUpperCase() });
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             this.appletHasError = true;
        //             this.appletErrorValue = "An Error Occured Durring Search. Error S404, request for search results failed."
        //         });
        // },
        
        // showSearchResults(appletLoadObj){
        //     // Clear Search Results Values
        //     this.values = [];
        //     //console.log("You Called Sir");
        //     //console.log(appletLoadObj)
        //     appletLoadObj.res.records.forEach((rec)=>{
        //         if(rec.record_status != ''){
        //             //rec._rowVariant = 'secondary';
        //             rec._cellVariants = { record_status: 'danger'}
        //         }
        //         var tmp = {
        //             ...rec,
        //             match_percent: Math.round(rec.match_strength * 100) + "%",
        //             type: this.capitalizeFirstLetter(rec.singular_type),
        //             match: rec.matched_on,
        //             text: rec.txt
        //         };
        //         this.values.push(tmp);
        //     })
        //     //this.values = appletLoadObj.res.records;
        //     console.log(this.values);
        //     // this.searchTerm = appletLoadObj.term;
        //     this.appletLoadCompleted = true;
        //     this.matchesCount = appletLoadObj.res.records.length;
        // },
        capitalizeFirstLetter(input){
            if(typeof input !== 'string') return '';
            return input.charAt(0).toUpperCase() + input.slice(1);
        }
        
    },
    beforeMount(){
        //console.log('Before Applet Mounted')
        // TODO: REPLACE THIS GARBAGE WITH A ROUTER
        //this.fetchPageData();
        //this.resetModalValue();
    },
    mounted(){
        // console.log('Applet Mounted')
        // TODO: REPLACE THIS GARBAGE WITH A ROUTER
        //console.log(this.pass);
        
        //this.resetModalValue();
        
    },
    afterMount(){
        this.refreshSelf();
    },
    updated(){
        // console.log('Applet Updated')
        // TODO: REPLACE THIS GARBAGE WITH A ROUTER
        //console.log(this.pass);
        //this.search();
        //this.resetModalValue();
        
    } 
}
</script>

<style>

</style>

<style scoped>
.modal-btn{
    font-size: 1.5rem;
    line-height: 1;
}
.array-spaced{
  margin-bottom: 0.5rem;
}
.input-text-alert{
  color: #dc3545;
}
</style>
